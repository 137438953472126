@import '~antd/dist/antd.css';
@import '../src/fonts/stylesheet.css';

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*=== Fooder Styles ===*/


@media screen and (max-width: 600px) {
    .main-logo-container {
        text-align: center;
    }

    .fooder-navigation {
        text-align: left !important;
    }

    .footerTitle {
        margin-top: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 5px;
    }

    .merchant-title {
        text-align: center !important;
        padding: 0px 30px !important;
        font-size: 25px !important;
    }

    .merchant-feature-card-container {
        min-height: 240px !important;
    }

    .item-grid-container {
        margin-top: 145px !important;
    }

    .fixed-title {
        text-align: center;
        left: 0 !important;
        padding-top: 10px;
    }

    .item-container {
        padding-top: 30px !important;
        margin-top: 40px !important;
        min-height: 20vh !important;
    }

    #delivery-information {
        margin-top: 90px !important;
    }

    .summary-items {
        margin-top: 0px !important;
    }

    .item-summary-card .item-image-container {
        width: 20% !important;
    }

    .item-summary-card .itemImage {
        height: 50px;
    }

    .item-summary-card .item-details {
        width: 80%;
    }

    .summary-name {
        width: 35% !important;
    }

    .summary-quantity {
        width: 10% !important;
    }

    .summary-total {
        width: 30% !important;
    }

    .order-confirmation-container {
        padding-top: 150px;
        margin-top: 50px !important;
        margin-bottom: 70px;
    }

    .item-summary-card .itemImage {
        height: 60px !important;
    }

    .signupBox {
        width: 98% !important;
    }

    .item-horizontal-card .itemPrice {
        color: #498d03;
        font-size: 14px;
    }
}

.header-container {
    background-color: white;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.26);
    min-height: 80px;
    padding-top: 10px;
}

.languageSelector .ant-select-selection {
    border: 0px !important;
}

.languageSelector .ant-select-selection-selected-value {
    margin-right: 5px !important;
}

.fooder-navigation {
    text-align: right;
    border-bottom: 0px;
}

.ant-menu-item-active, .ant-menu-item-selected {
    color: #FF5E3A !important;
    border-bottom: 2px solid #FF5E3A !important;
}

.section-title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 5px;
}

.section-description {
    font-size: 15px;
}

.restaurant-card-image-container {
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    align-items: flex-end;
}

.delivery-detail-container {
    color: white;
    height: 35px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    font-size: 14px;
}

.restaurant-detail-container {
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
}

.restaurant-detail-container h5 {
    padding: 10px 20px 0px 20px;
    margin-bottom: 4px;
}

.restaurant-detail-container p {
    padding: 0px 20px;
    color: #8A8A8A;
    margin-bottom: 8px;
}

.restaurant-tags {
    border-top: 1px solid #F6F6F6;
    font-size: 10px;
    padding: 8px 20px !important;
}

.footer-container {
    padding-top: 40px;
    background-color: #F8F8F8;
    padding-bottom: 40px;
}

.menu-footer-container {
    padding-top: 20px;
    background-color: #F8F8F8;
    padding-bottom: 20px;
}

.footerTitle {
    margin-bottom: 20px;;
}

.incy-primary-button {
    background-color: #FF5E3A;
    border-color: #eb5938;
    color: white;
}

.incy-primary-button:hover {
    background-color: #eb5938;
    color: white;
    border-color: #eb5938;
}

.incy-primary-button:focus {
    background-color: #eb5938;
    color: white;
    border-color: #eb5938;
}

.merchant-slide-container {
    height: 400px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.merchant-slider-content h3 {
    color: white;
    line-height: 1;
    font-weight: 400;
}

.merchant-title {
    text-align: center;
    padding: 0px 170px;
    color: #434343;
    margin-bottom: 80px;
}

.merchant-feature-card-container {
    min-height: 300px;
}

.merchant-feature-card-container h4 {
    margin: 10px 0px;
    font-size: 20px;
}

.merchant-feature-card-container p {
    padding: 0px 30px;
    color: #8A8A8A;
}

.merchant-feature-card-container img {
    width: 100px;
    margin-bottom: 10px;
}

.merchant-contact-section {
    padding-bottom: 40px;
    padding-top: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    color: #434343;
}

.merchant-contact-section p {
    margin-bottom: 2px;
}

.merchant-contact-section a {
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    padding: 0px 5px;
    color: #434343;
}

.merchant-contact-section a:hover {
    color: #eb5938;
}

.gravity-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.full-screen {
    height: 100vh;
    width: 100vw;
}

/*=== Menu Styles ===*/

/*Common Incy Styles*/
.incyButton:hover {
    cursor: pointer;
}

/*Main Header*/

.incy-bg-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.incy-align-horizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-right-widgets {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.account-ico {
    padding: 0px 20px;
}

.cart-background {
    background-color: #498D05;
    border-radius: 40px;
    min-height: 35px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    min-width: 150px;
}

.cart-background {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.cart-background svg {
    color: #ffffff;
}

.cart-background img {
    margin-top: -2px;
}

.cart-background h6 {
    margin-top: 7px;
    color: white;
}

.restaurant-details {
    margin-top: 20px;
}

.restaurant-details h3 {
    color: #ffffff;
    font-size: 30px;
    margin-bottom: 5px;
}

.restaurant-details p {
    color: #ffffff;
    max-width: 80%;
}

.restaurant-details-mobile {
    margin-top: 20px;
    padding: 0px 20px;
}

.restaurant-details-mobile h3 {
    color: #ffffff;
    font-size: 20px;
    margin-bottom: 5px;
}

.restaurant-details-mobile p {
    font-size: 12px;
    color: #ffffff;
    line-height: 1.2;
}

.contact-details {
    color: #ffffff;
    font-size: 18px;
    padding: 0px 20px;
}

.contact-details svg {
    margin-right: 10px;
}

.contact-details span {
    font-size: 18px;
    font-weight: 600;
}

/*Food Menu*/
.menuContainer {
    min-height: 300px;
}

.menuContainer .ant-menu-submenu-title, .ant-menu-item {
    height: 30px !important;
    line-height: 30px !important;
}

.menuContainer .ant-menu-inline {
    border-right: 0px;
}

.incyMenuLevelOne {
    color: #3A3A3A;
    font-weight: 400;
    font-size: 16px !important;
}

.incyMenuLevelOne:hover {
    color: #FE431E;
    font-weight: 500;
    font-size: 16px !important;
}

.incyMenuLevelTwo {
    color: #3A3A3A;
    font-weight: 400;
    font-size: 16px !important;
}

.incyMenuLevelTwo:hover {
    color: #FE431E;
    font-weight: 500;
    font-size: 16px !important;
}

.incyMenuLevelThree {
    color: #3A3A3A;
    font-weight: 400;
    font-size: 14px !important;
}

.incyMenuLevelThree:hover {
    color: #FE431E;
    font-weight: 500;
    font-size: 14px !important;
}

.ant-menu-submenu-arrow {
    color: black;
    font-weight: bold;
    font-size: 20px !important;
}

.ant-menu-submenu-arrow:hover {
    color: #FE431E;
    font-weight: bold;
    font-size: 20px !important;
}

.ant-menu-item-selected {
    background-color: rgba(255, 67, 33, 0.14) !important;
    border-right: 3px solid #FE431E !important;
    border-bottom: 0px !important;
}

.ant-menu-item::after {
    border-right: 0px !important;
}

/*Item Grid*/

.incySelectedItem {
    font-weight: bold;
    font-size: 18px !important;
    color: #3A3A3A;
}

.showMore {
    font-weight: 600;
    color: #3A3A3A;
    font-size: 12px;
    text-align: right;
    float: right;
    margin-top: 6px;

}

/*Item Card*/
.itemCardContainer {
    min-height: 340px; /*TODO: Make 390 once item description is enabled*/
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.17);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.itemImage {
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px 5px 0px 0px;
}

.itemName {
    font-size: 16px !important;
    text-align: center;
    font-weight: 600;
    width: 100%;
    padding: 10px;
    margin-bottom: 0px !important;
    max-height: 58px;
}

.itemDescription {
    text-align: center;
    font-size: 12px;
    margin-bottom: 5px !important;
}

.itemPrice {
    font-weight: 600;
    text-align: center;
}

.quantityButtonContainer {
    background-color: #498D05;
    height: 25px;
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    font-weight: 600;
    margin-top: -5px;
}

.customiseButton {
    color: #FE431E;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
}

/*Footer*/
.footerContainer {
    background-color: black;
    min-height: 250px;
    margin-top: 40px;
    padding: 40px 0px;
}

.footerLinks {
    color: white;
    margin-bottom: 5px;
}

.footerSocialIcons {
    color: white;
    text-align: right;
}

.footerSocialIcons svg {
    margin: 0px 10px;
}

@media (min-width: 1400px) {
    .mini-cart {
        top: 85px;
        float: right;
        right: 405px !important;
    }
}

@media (min-width: 1200px) {
    .mini-cart {
        top: 85px;
        float: right;
        right: 135px;
    }
}

@media (max-width: 576px) {
    .mobile-menu-button {
        display: inline !important;
    }

    .itemDetailContainer {
        flex-direction: column;
    }

    .itemDetailContainer .price-container {
        text-align: left !important;
    }

    .mini-cart-item-container .quantityButtonContainer {
        padding: 8px !important;
    }

    .order-confirmation-container {
        height: 275px !important;
    }

    .item-horizontal-card h4 {
        max-height: 40px !important;
        overflow: hidden !important;
    }

    .variant-scroller {
        display: flex;
        overflow-x: scroll;
    }

    .variant-scroller .ant-tag {
        margin-bottom: 0px !important;
    }

    .variant-scroller::-webkit-scrollbar {
        height: 5px;
    }

    .variant-scroller::-webkit-scrollbar-track-piece {
        height: 5px;
    }

    .variant-scroller::-webkit-scrollbar-thumb {
        height: 5px;
        background-color: rgba(136, 136, 136, 0.13);
        border-radius: 5px;
        padding-top: 20px;
    }

    .item-horizontal-card p {
        margin-top: 0px !important;
    }

    .item-horizontal-card {
        height: 80px !important;
    }

    .item-horizontal-card .item-actions {
        top: 40px !important
    }

    .item-horizontal-card .itemImage {
        height: 70px !important;
        border-radius: 5px !important;
    }

    .item-horizontal-card .item-description {
        display: none !important;
    }

    .menu-footer-container {
        text-align: center !important;
    }

    .footer-copyright {
        text-align: center !important;
    }

    .item-grid-container {
        margin-top: 96px !important;
    }

    .fixed-title {
        left: 0 !important;
    }

    .item-container {
        padding-top: 60px !important;
        margin-top: 40px !important;
    }

    #delivery-information {
        margin-top: 90px !important;
    }

    .summary-items {
        margin-top: 0px !important;
    }

    .item-summary-card .item-image-container {
        width: 20% !important;
    }

    .item-summary-card .itemImage {
        height: 50px;
    }

    .item-summary-card .item-details {
        width: 80%;
    }

    .summary-name {
        width: 35% !important;
    }

    .summary-quantity {
        width: 10% !important;
    }

    .summary-total {
        width: 30% !important;
    }

    .order-confirmation-container {
        padding-top: 160px;
    }

    .summary-modifier-container {
        font-size: 10px;
    }

    .item-horizontal-card .itemPrice {
        color: #498d03;
        font-size: 14px !important;
    }
}

.mini-cart {
    width: 470px !important;
}

.mini-cart .ant-modal-body {
    padding: 20px 00px;
}

.mini-cart .mini-cart-title {
    display: flex;
}

.mini-cart .label {
    font-size: 18px;
    margin-right: 20px;
}

.mini-cart .total {
    font-size: 22px;
    font-weight: 600;
}

.mini-cart .ant-modal-header {
    border-bottom: 0px;
    height: 40px;
}

.mini-cart-total {
    color: #333333;
    line-height: 1;
}

.mini-cart-total h4 {
    font-size: 20px;
    margin-bottom: 0px !important;
    padding: 0px;
}

.mini-cart-total h2 {
    font-size: 25px;
    font-weight: 700;
}

.mini-cart-checkout {
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    height: 40px;
    background-color: #FE431E;
    color: white;
    border: #FE431E;
    margin-bottom: 10px;
}

.mini-cart-checkout div {
    display: flex;
    justify-content: space-between;
}

.mini-cart-checkout:hover {
    background-color: #e53e1f;
    color: white;
    border: #FE431E;
}

.mini-cart-checkout:focus {
    background-color: #e53e1f;
    color: white;
    border: #FE431E;
}

.mini-cart-total-container {
    padding: 0px 18px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.18);
}

.mini-item-image {
    height: 70px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
}

.mini-cart-item-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.mini-cart-item-container h4 {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
}

.mini-cart-item-container p {
    font-size: 10px;
    color: #333333;
}

.facebook-login-btn {
    background-color: #4267B2;
    width: 100%;
    color: white;
    height: 50px;
    padding: 0px 20px;
    border: 0px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
}

.facebook-login-btn:hover {
    background-color: #3958b2;
    color: white;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
}

.facebook-login-btn:focus {
    background-color: #4267B2;
    color: white;
    height: 50px;
    padding: 0px 20px;
    border: 0px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
}

.facebook-login-btn img {
    width: 30px;
    margin-right: 15px;
}

.google-login-btn {
    background-color: #ffffff;
    color: #787878;
    width: 100%;
    height: 50px;
    padding: 0px 20px;
    border: 0px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
}

.google-login-btn:hover {
    background-color: white;
    color: #787878;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
}

.google-login-btn:focus {
    background-color: white;
    color: #787878;
    height: 50px;
    padding: 0px 20px;
    border: 0px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
}

.google-login-btn img {
    width: 30px;
    margin-right: 15px;
    margin-left: -12px;
}

.summary-item-container {
    padding: 20px;
    background-color: #ffffff;
    margin-top: 15px !important;
}

.summary-item-image {
    height: 65px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.summary-details {
    display: flex;
    align-items: center;
    height: 85px;
}

.summary-details h6 {
    color: #333333;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 5px;
    height: 36px;
    overflow: hidden;
}

.summary-price-details {
    color: rgba(0, 0, 0, 0.51);
    font-weight: 800;
    font-size: 11px;
    margin-top: -20px !important;
}

.green-button {
    background-color: #498D05;
    color: white;
    font-size: 16px;
    width: 100%;
    border-color: #498D05;
    font-weight: 600;
    height: 40px;
    margin-top: 10px;
}

.green-button:hover {
    background-color: #2f8d13;
    border-color: #498D05;
}

.green-button:focus {
    background-color: #2f8d13;
    border-color: #498D05;
}

.back-navigation {
    display: flex;
    align-items: center;
    margin-left: -5px;
    padding-top: 20px;
    text-decoration: underline;
}

.back-navigation svg:hover {
    cursor: pointer;
}

.back-navigation h6:hover {
    cursor: pointer;
}

.back-navigation h6 {
    margin-top: 6px;
    margin-left: 5px;
    padding-top: 4px;
}

.back-navigation svg {
    font-size: 16px;
}

.delivery-detail-form .ant-form-item, label {
    margin-bottom: 0px;
}

.signUp-form-title {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
}

.signUp-form-description {
    font-size: 12px;
    color: #333333b0;
    max-width: 300px;
    margin-left: auto;
    line-height: 16px;
    margin-right: auto;
}

.sign-up-title p {
    font-size: 12px;
    font-weight: 600;
}

.sign-up-title span {
    color: #FE431E;
    font-size: 14px;
}

.sign-up-title span:hover {
    cursor: pointer;
}

.disable-text-selection {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.order-confirmation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 150px;
}

.order-confirmation-container img {
    width: 75px;
    margin-bottom: 20px;
}

.order-confirmation-container p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 20px;
}

.order-confirmation-container h6 {
    font-weight: 600;
    margin-bottom: 20px;
}

.order-confirmation-container h2 {
    font-size: 26px;
    border: 2px dashed rgba(51, 51, 51, 0.51);
    border-radius: 5px;
    padding: 5px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    color: #FE431E;
}

.order-confirmation-container .green-button {
    width: 50%;
}

.mobile-menu-button {
    background-color: #FE431E;
    color: white;
    border-color: #FE431E;
    border-radius: 0px 0px 5px 5px;
    width: 100px;
    margin-left: 20px;
}

.mobile-menu-button:hover {
    background-color: #fe3924;
    border-color: #FE431E;
}

.mobile-menu-button:focus {
    background-color: #fe3924;
    border-color: #FE431E;
}

.mobile-menu-container .ant-drawer-content-wrapper {
    width: 90% !important;
}

.mobile-menu-container .ant-drawer-close {
    background-color: #3A3A3A;
    border-radius: 50px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    height: 50px;
    width: 50px;
}

.mobile-menu-container .ant-menu-inline {
    border-right: 0px;
}

.mobile-scroller-menu {
    overflow-x: hidden !important;
}

.headerMinimal {
    height: 85px !important;
}

.headerMinimal .mobile-search-header {
    margin-top: 10px;
}

.headerMinimal .restaurant-details-mobile {
    display: none;
}

.itemDetailPopup {
    width: 600px;
}

.itemDetailPopup .ant-modal-header {
    border-bottom: 0px !important;
}

.itemDetailPopup .itemImage {
    height: 200px;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.itemDetailPopup h2 {
    font-size: 30px;
    font-weight: 700;
}

.itemDetailPopup h4 {
    font-size: 22px;
    font-weight: 600;
}

.itemDetailPopup .quantityButtons {
    background-color: transparent;
    padding: 0px;
    color: #3A3A3A;
}

.itemDetailPopup .addToCart {
    height: 35px;
}

.itemDetailPopup .addToCart:hover {
    background-color: #32740c;
}

.itemDetailPopup .addToCart button {
    width: 100%;
    color: #ffffff;
    text-align: center !important;
}

.itemDetailPopup .addToCart button:hover {
    color: #ffffff;
}

.itemDetailPopup .addToCart button:focus {
    color: #ffffff;
}

.itemDetailPopup .quantityButtons svg {
    color: #333333;
    height: 35px !important;
    width: 35px !important;
}

.itemDetailHeader {
    padding-bottom: 10px;
    margin-left: -34px !important;
    margin-right: -34px !important;
    margin-top: -26px;
    border-radius: 5px 5px 0px 0px;
}

.itemDetailPopup .ant-modal-close:focus {
    outline: none !important;
}

.itemDetailPopup .ant-modal-close-x:focus {
    outline: none !important;
}

.itemOptionPopup .ant-modal-close:focus {
    outline: none !important;
}

.itemOptionPopup .ant-modal-close-x:focus {
    outline: none !important;
}

.itemDetailHeader hr {
    margin-top: 0px !important;
    margin-bottom: 4px;
    border: 0;
    border-top: 1px solid rgba(132, 132, 132, 0.05);
}

.itemDetailContainer h4 {
    margin-bottom: 0px !important;
}

.itemDetailContainer h2 {
    margin-bottom: 0px !important;
}

.item-horizontal-card {
    height: 130px;
    overflow: hidden;
    border-top: 1px solid #F5F5F5;
    padding-top: 8px;
}

.item-horizontal-card .item-details {
    padding: 5px 15px;
}

.item-horizontal-card h4 {
    padding: 0px;
    margin-bottom: 0px;
    max-height: 39px;
    line-height: 20px;
    overflow: hidden;
}

.item-horizontal-card p {
    color: #3A3A3A;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 0px;
    height: 32px;
    overflow: hidden;
}

.item-horizontal-card .itemImage {
    height: 120px;
    border-radius: 5px;
}

.item-horizontal-card .itemPrice {
    color: #498d03;
    font-size: 20px;
}

.item-horizontal-card .item-quantity {
    display: flex;
    align-items: center;
}

.item-horizontal-card .item-quantity svg {
    font-size: 20px;
    color: #333333;
}

.item-horizontal-card .item-quantity span {
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 600;
    color: #000000;
}

.item-horizontal-card .item-actions {
    position: absolute;
    top: 80px;
    width: 100%;
    padding-right: 30px;
}

.item-horizontal-card .item-actions button {
    background-color: #498D05;
    border-color: #498D05;
}

.item-horizontal-card .item-actions button:hover {
    background-color: #5ea50c;
    border-color: #498D05;
}

.btnAddToCart {
    height: 35px;
    width: 35px;
    margin-top: -4px;
    background-color: #498D05;
    border-color: #498D05;
}

.btnAddToCart:hover {
    background-color: #5ea50c;
    border-color: #498D05;
}

.btnAddToCart:focus {
    background-color: #5ea50c;
    border-color: #498D05;
}

.order-confirmation-container .ant-spin-dot-item {
    background-color: #FE431E;
    width: 25px !important;
    height: 25px !important;
}

.order-confirmation-container .payment-loading {
    margin-bottom: 20px;
}

.order-confirmation-container i {
    width: 25px;
    height: 25px;
}

.order-confirmation-container .ant-spin-dot {
    font-size: 60px;
}

.radio-list {
    display: block;
    height: 30px;
    line-height: 30px;
}

.radio-list .ant-radio-inner {
    border-color: #FE431E;
}

.radio-list .ant-radio-inner::after {
    background-color: #FE431E;
}

.radio-list .ant-radio-inner:hover {
    border-color: #e72f0a !important;
}

.radio-list .ant-radio-inner:hover {
    border-color: #e72f0a !important;
}

.align-times {
    display: flex;
    justify-content: space-between;
}

.delivery-time-tabs .ant-tabs-tab {
    padding: 5px 0px !important;
}

.delivery-time-tabs .ant-tabs-tab:hover {
    color: #000000;
}

.delivery-time-tabs .ant-tabs-tab-active {
    color: #000000;
}

.delivery-time-tabs .ant-tabs-ink-bar {
    background-color: #000000;
    width: 78px;
}

.price-range {
    font-size: 18px;
    font-weight: 500;
    color: rgba(138, 138, 138, 0.4);
}

.food-types {
    font-size: 10px;
    margin-right: -8px;
}

.header-login-button {
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 30px;
    margin-right: 5px;
    font-size: 12px;
}

.header-login-button:hover {
    background-color: transparent;
    border: 1px solid #ffffff;
}

.header-login-button:focus {
    background-color: transparent;
    border: 1px solid #ffffff;
}

.variant-button-container {
    display: flex;
    align-items: flex-end;
    height: 30px;
}

.variant-button-container button {
    font-size: 12px;
    background-color: #FFFFFF !important;
    border: 0px;
    padding: 0px;
    color: #0170FE;
    height: 20px;
    margin-bottom: -4px;
}

.variant-button-container button:hover {
    color: #0170FE;
}

.variant-button-container svg {
    margin-right: 8px;
    color: #0170FE;
    font-size: 14px !important;
    margin-bottom: 2px !important;
}

.itemCount {
    display: none;
    margin-right: 10px;
    font-weight: bold;
    color: #FE431E;
}

@keyframes animateQty {
    0% {
        font-size: 16px;
        opacity: 0.5
    }
    50% {
        font-size: 16px;
        opacity: 1
    }
    100% {
        font-size: 16px;
        opacity: 0
    }
}

.compact-form .ant-form-item-label {
    line-height: 30px;
}

.footer-contact svg {
    color: #333333;
    margin-right: 5px;
}

.footer-contact a {
    font-size: 12px;
    color: #333333;
    padding-top: 2px;
}

.footer-contact a:hover {
    color: #FE431E;
}

.footer-contact svg:hover {
    color: #FE431E;
}

.signUp-form .ant-form-item {
    margin-bottom: 10px !important;
}

.signUp-form .ant-form-item-label {
    display: none;
}

.sign-up-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.signupBox {
    border-radius: 5px;
    width: 25%;
}

.modifier-container {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.modifier-container .modifier-action-controller {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    font-weight: 600;
}

.modifier-container svg {
    font-size: 24px;
    color: black;
}

.modifier-container .modifier-image {
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: -10px 0px -10px 0px;
    border-radius: 0px 5px 5px 0px;
}

.category-item-row {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.19);
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    font-weight: 400;
}

.category-item-row .ant-col {
    padding: 0px 10px;
}

.profile-name {
    padding: 10px;
    text-align: center;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.optionContainer {
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.26);
    padding: 10px;
    height: 120px;
}

.optionContainer-selected {
    background-color: rgba(255, 67, 33, 0.14);
}

.optionContainer .touch-icon {
    background-color: white;
    position: absolute;
    color: #FF5E3A;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    top: 4px;
    right: 5px;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.26);
    font-size: 15px;
}

.optionContainer .option-image {
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: -8px -5px 5px -5px;
    border-radius: 5px 5px 0px 0px;
}

.optionContainer .option-type {
    color: black;
    font-weight: 600;
    margin-bottom: 4px;
    font-size: 16px;
    max-height: 20px;
    overflow: hidden;
}

.optionContainer .option-name {
    color: #6A6B6C;
    font-weight: 400;
    font-size: 14px;
    max-height: 32px;
    overflow: hidden;
}

.optionContainer .option-name-selected {
    color: black;
    font-weight: 500;
}

.btn-orange {
    background-color: #F95C38;
    border-color: #F95C38;
    color: white;
}

.btn-orange:hover {
    background-color: #fa6341;
    color: white;
    border-color: #ff4d25;
}

.btn-orange:focus {
    background-color: #fa6341;
    color: white;
    border-color: #ff4d25;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .header-right-widgets {
        position: absolute !important;
        right: 0 !important;
    }

    .price-range {
        display: none !important;
    }

    .food-types {
        display: none !important;
    }

    .restaurant-details-tablet .ant-avatar {
        display: none !important;
    }

    .restaurant-details-tablet {
        justify-content: space-around !important;
    }

    .selected-cat-name-tab {
        margin-top: -45px !important;
    }

    .selected-cat-name-tab h5 {
        text-align: center;
    }

    .selected-cat-name-tab .ant-affix {
        top: 285px !important;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .header-right-widgets {
        position: absolute !important;
        right: 0 !important;
    }

    .price-range {
        display: none !important;
    }

    .food-types {
        display: none !important;
    }

    .restaurant-details-tablet {
        justify-content: space-around !important;
        text-align: center;
    }

    .restaurant-details-tablet .ant-avatar {
        display: none !important;
    }

    .selected-cat-name-tab .ant-affix {
        top: 285px !important;
    }

    .selected-cat-name-tab {
        margin-top: -45px !important;
    }

    .selected-cat-name-tab h5 {
        text-align: center;
    }

    .mobile-menu-button {
        display: inline !important;
        position: absolute;
        top: 0;
        width: 100px !important;
        left: 45px;
        top: 28px;
        border-radius: 5px !important;
        z-index: 100 !important;
    }

    .footer-contact {
        text-align: center;
    }

    .footer-copyright {
        text-align: center !important;
    }
}

.fixed-title {
    position: fixed;
    z-index: 10;
    margin-top: -30px;
    width: 100%;
    background-color: white;
}

.mobile-header {
    min-height: 100px;
    background-size: cover;
    position: fixed;
    width: 100%;
    z-index: 150;
    padding: 20px;
}

.item-grid-container {
    margin-top: 245px;
}

.item-container {
    margin-top: 40px;
    min-height: 65vh;
}

.item-summary-card .itemImage {
    margin-right: 10px;
    border-radius: 5px;
    height: 40px;
}

.back-btn-container {
    color: white;
    font-size: 24px;
}

.sidebar-menu-tree {
    line-height: 2;
    margin-right: 10px;
}

.sidebar-menu-tree .anticon {
    font-size: 16px !important;
}

.sidebar-menu-tree .ant-tree-switcher {
    margin-top: -2px !important;
}

.sidebar-menu-tree .ant-tree-treenode-switcher-close {
    font-size: 16px;
    height: 35px;
    border-bottom: 2px solid transparent;
}

.sidebar-menu-tree .ant-tree-treenode-switcher-close:hover {
    border-bottom: 2px solid #F95C38;
    color: #000000;
}

.sidebar-menu-tree .ant-tree-node-content-wrapper:hover {
    background-color: transparent !important;
}

.sidebar-menu-tree .ant-tree-node-content-wrapper:focus {
    background-color: #ffe4df !important;
}

.sidebar-menu-tree .ant-tree-node-selected {
    background-color: #ffe4df !important;
}

.ant-tree-node-selected .ant-tree-title {
    color: #000000;
    font-weight: 600;
    background-color: #ffe4df !important;
}

.sidebar-menu-tree .ant-tree-treenode-selected {
    color: #000000;
    font-weight: 400;
    background-color: #ffe4df !important;
    width: 100%;
    border-right: 4px solid #F95C38;
}

.ant-tree-child-tree .ant-tree-treenode-switcher-close {
    display: flex;
    align-items: center;
    padding-top: 0px !important;
}

.order-action-modal .action-button {
    width: 100%;
    height: 8vh;
    margin: 1%;
    line-height: 20px;
    font-size: 30px;
}

.order-action-modal .ant-form-item {
    margin-bottom: 0px !important;
}

.order-action-modal .accept-button {
    background-image: linear-gradient(rgb(141, 196, 77), #5ea50c);
    color: #ffffff;
}

.order-action-modal .decline-button {
    background-image: linear-gradient(#de7662, #FE431E);
    color: #ffffff;
}


/*NEW UI STYLES*/
:root {
    --unnamed-color-37517e: #37517E;
    --unnamed-color-6e8691: #6E8691;
}
