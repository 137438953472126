html{
  overflow-x: hidden;
  overflow-y: auto;
}

:root{
    --primary-color: #FA6400;
    --hover-button-color: #e75b00;
    --bold-title-font: tt_norms_probold;
    --form-title-font-size: 1.3rem;
    --title-color: #37517E;
    --form-title-color: #37517E;
    --paragraph-color: #6E8691;
    --form-underline-color: #00000014;
    --form-input-text-size: 0.9rem;
    --form-input-text-weight: normal;
    --form-input-color: #37517E;
    --form-text-color: #707070;
    --font-family: tt_norms_promedium;
    --font-regular: tt_norms_promedium;
    --link-color: #007BAD;
    --input-background-color: #f3f5f7;
    --paragraph-font: tt_norms_proregular;
    --font-medium: tt_norms_promedium;
    --text-green: #6ABB15;
    --text-red: #d40505;
    --text-dark-blue: #222B45;
    --danger-red: #F23339;
}
button:focus {
    outline: none !important;
    box-shadow: none !important;
}
.MuiFormHelperText-root.Mui-error {
    color: #f44336 !important;
}
.MuiCircularProgress-indeterminate {
    color: var(--primary-color) !important;
}
p {
    font-family: var(--paragraph-font) !important;
    font-size: 1rem;
    margin-bottom: 0px !important;
    letter-spacing: -0.01px !important;
    line-height: 1.5;
}
h1, h2, h3, h4, h5, h6 {
    font-family: var(--bold-title-font) !important;
    color: var(--form-title-color) !important;
    margin-bottom: 10px;
}
input {
    color: var(--title-color) !important;
    font-size: 1rem !important;
    font-family: var(--font-regular) !important;
}
textarea {
    color: var(--title-color) !important;
    font-size: 1rem !important;
    font-family: var(--font-regular) !important;
}
.MuiFormLabel-root {
    font-family: var(--font-regular) !important;
}
.non-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.main-logo-incy {
    width: 102px;
    height: 50px;
}
.incy-hoverable:hover {
    cursor: pointer;
}
.primary-button-incy {
    background-color: var(--primary-color) !important;
    font-family: var(--font-family) !important;
    color: #ffffff !important;
    font-size: 1rem !important;
    font-weight: normal !important;
    box-shadow: none !important;
    height: 50px;
    border-radius: 10px !important;
}
.primary-button-incy:focus {
    border: 0px !important;
}
.primary-button-incy:hover {
    outline: none !important;
    border-color: var(--primary-color) !important;
}
.danger-button-incy {
    background-color: var(--danger-red) !important;
    font-family: var(--font-family) !important;
    color: #ffffff !important;
    font-size: 1rem !important;
    font-weight: normal !important;
    box-shadow: none !important;
    height: 50px;
    border-radius: 10px !important;
}
.danger-button-incy:focus {
    border: 0px !important;
}
.danger-button-incy:hover {
    outline: none !important;
    border-color: var(--danger-red) !important;
}
.secondary-button-incy {
    background-color: #e0e0e0 !important;
    font-family: var(--font-family) !important;
    color: #000000 !important;
    font-size: 1rem !important;
    font-weight: normal !important;
    box-shadow: none !important;
    height: 50px;
    border-radius: 10px !important;
}
.secondary-button-incy:focus {
    border: 0px !important;
}
.form-title {
    color: var(--form-title-color);
    text-align: left;
    letter-spacing: -0.22px;
    opacity: 1;
}

.incy-container {
    padding: 0px 30px 30px 30px;
}
.container-padding {
    padding-right: 25px;
    padding-left:  25px;
}

.account-info-form input {
    color: var(--form-title-color);
    text-align: left;
    letter-spacing: 0px;
}

.account-info-form .form-title {
    margin-bottom: 0px !important;
}

.account-info-form .location-info {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #b8b8b8;
}

.account-info-form .location-info svg {
    color: #b8b8b8;
    margin-top: -1px;
}

.account-info-form .MuiGrid-item {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
}

.account-info-form label {
    color: var(--paragraph-color);
    text-align: left;
    letter-spacing: 0px;
}

.account-info-form input {
    color: var(--form-title-color);
    text-align: left;
    letter-spacing: 0px;
}

.account-info-form textarea {
    color: var(--form-title-color);
    text-align: left;
    letter-spacing: 0px;
    line-height: 1.5;
}

.account-info-form select {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #37517e;
}

.account-info-form svg {
    color: #37517e;
}

.btn-primary {
    background-color: #fa6400 !important;
    color: white !important;
    height: 50px;
    border-radius: 10px !important;
}

.btn-primary:focus {
    background-color: #fa6400 !important;
    color: white !important;
    height: 50px;
    border-radius: 10px !important;
    border: 0px !important;
}

.main-logo {
    width: 78px;
    height: 43px;
    object-fit: contain;
}

.account-info-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    overflow: scroll;
}

.account-info-desktop-container {
    top: 300px;
    margin: 0px auto;
    padding: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    opacity: 1;
}

.account-info-desktop-container .button-container {
    text-align: center !important;
}

.MuiButton-root {
    font-size: 1rem !important;
    text-transform: none !important;
    color: #ffffff
}

.MuiInput-underline:before {
    border-color: #00000014 !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: #00000014 !important;
}

.MuiInput-underline:after {
    border-color: #00000014 !important;
}

.incy-header-container {
    padding: 15px 25px;
}

.test-incy .selectEmpty {
    margin-top: 0px !important;
}

.country-auto-complete .MuiAutocomplete-popupIndicator {
    display: none !important;
}
.MuiFormLabel-root.Mui-focused {
    color: var(--paragraph-color) !important;
}

.form-title {
    font-size: var(--form-title-font-size);
    font-family: var(--font-family) !important;
}
.form-title .otp-phone{
    color: var(--primary-color);
    letter-spacing: 2px;
}
.phone-container-class {
    border-bottom: 1px solid var(--form-underline-color);
    margin-top: 1.5em;
    margin-bottom: 2em;
}
.phone-container-class .flag-dropdown{
    background-color: transparent !important;
    border: 0px !important;
    border-radius: 50px !important;
    height: 40px;
    width: 40px;
    padding-left: 5px;
}
.phone-container-class .arrow{
    margin-left: 5px;
    border-left: 6px solid transparent !important;
    border-right: 6px solid transparent !important;
    border-top: 8px solid var(--form-underline-color) !important;
    border-bottom: 0px !important;
}
.phone-container-class .phone-drop-down-class{
    text-align: left !important;
    line-height: 1 !important;
}

.phone-input-class {
    border: 0px !important;
    margin-left: 20px !important;
    font-size: var(--form-input-text-size) !important;
    color: var(--form-input-color) !important;
    height: 40px !important;
    font-weight: var(--form-input-text-weight) !important;
    font-family: var(--font-family) !important;
}
.phone-input-class:focus {
    box-shadow: none !important;
}
.account-info-desktop-container .phone-verification-form-container {
    margin-left: 20px;
    padding-right: 20px;
}
.phone-verification-form-container .codeInput {
    margin-top: 2em;
    margin-bottom: 2em;
    width: 100% !important;
    margin-left: -5px;
}
.phone-verification-form-container .codeInput input{
    width: 13% !important;
    font-family: var(--font-medium) !important;
}
.phone-verification-mobile .codeInput{
    margin-left: 5px;
    margin-right: 5px;
}
.phone-verification-mobile .codeInput input{
    width: 13% !important;
}
.phone-verification-form-container .change-number-class{
    color: var(--link-color);
}
.phone-verification-form-container .change-number-class:hover{
    cursor: pointer;
}
.phone-verification-form-container p{
    margin-bottom: 2em !important;
    font-size: 1rem;
    color: var(--form-text-color);
    font-weight: normal;
}
.old-actions {
    display: none;
}
.old-actions button{
    font-size: 0.8rem !important;
}
.phone-verification-mobile button {
    width: 100% !important;
}
.incy-search-input {
    border-radius: 10px;
    background-color: var(--input-background-color);
    width: 100%;
    height: 45px;
    border: none !important;
    padding: 7px 10px !important;
    font-size: var(--form-input-text-size) !important;
    color: var(--form-input-color);
    margin-bottom: 8px !important;
}
.incy-search-input .MuiInput-underline:before{
    border: none !important;
}
.incy-search-input .MuiInput-underline:after{
    border: none !important;
}
.incy-restaurant-header .restaurant-header-image{
    height: 194px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
}
.incy-restaurant-header .restaurant-logo{
    height: 86px;
    width: 86px;
    position: relative;
    top: -43px;
    left: 25px;
    border-radius: 10px;
    padding: 15px;
    background-color: white;
    border: 1px solid #E6E6E6;
}
.incy-restaurant-header .restaurant-info-container{
    width: 250px;
    position: relative;
    top: -88px;
    left: 110px;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    color: #6e8691;
}
.incy-restaurant-header .restaurant-info-container img{
    margin-top: -3px;
    margin-right: 3px;
}
.incy-restaurant-header .restaurant-info-container .ratings{
    color: var(--form-title-color) !important;
}
.incy-restaurant-details {
    margin-top: -75px;
    padding-left: 25px;
}
.incy-restaurant-details h2 {
    color: var(--form-title-color);
    font-family: var(--bold-title-font) !important;
    font-size: 1.5rem;
}
.restaurant-opened {
    margin-top: 8px;
    font-size: 0.8rem;
    letter-spacing: 0px;
    font-family: var(--font-regular);
}
.restaurant-opened .opened {
    color: var(--text-green);
    font-family: var(--font-regular);
}

.restaurant-closed-now {
  font-family: var(--font-regular) !important;
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 8px;
}

.restaurant-closed {
    color: var(--danger-red) !important;
    font-family: var(--font-regular) !important;
    font-size: 0.8rem;
    margin-top: 8px;
}
.venue-info-link {
    font-size: 0.8rem;
    font-family: var(--font-medium);
    color: var(--paragraph-color);
    border-top: 1px solid #B8BBC635;
    padding-top: 10px;
    margin-top: 35px;
}
.venue-info-link:hover {
    cursor: pointer;
}
.venue-info-link img {
    margin-left: 5px;
    height: 29px;
    width: 29px;
    padding: 6px;
    background-color: #F2F2F2;
    border-radius: 50px;
}
.menu-container {
    margin-top: 35px;
}
.incy-restaurant-menu h3 {
    color: var(--title-color);
    font-size: 1.4rem;
}
.incy-menu-tree {
    font-family: var(--font-family);
}
.incy-menu-tree li{
    padding: 8px 0px;
}
.incy-menu-tree .top-level .MuiTreeItem-label{
    font-family: var(--font-medium) !important;
    font-size: 1rem !important;
    color: var(--title-color);
    font-weight: 600;
    margin-left: 10px;
    background-color: transparent !important;
}
.incy-menu-tree .sub-level .MuiTreeItem-label{
    font-family: var(--paragraph-font) !important;
    font-weight: 500 !important;
}
.incy-menu-tree .MuiTreeItem-label:hover{
    background-color: transparent !important;
}
.incy-menu-tree .Mui-selected > .MuiTreeItem-content .MuiTreeItem-label{
    background-color: transparent !important;
}
.account-info-desktop-container .continue-button {
    max-width: 160px;
}
.mobile-menu-drawer .MuiDrawer-paper {
    width: 100% !important;
    position: relative !important;
    padding-top: 20px;
}
.sider-header-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    padding: 50px 25px;
    z-index: 100;
    margin-bottom: -100px;
}
.sider-header-container .sider-button{
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    box-shadow: 0px 3px 6px #00000010;
}
.sider-header-container h4{
    margin-bottom: 0px !important;
    font-size: 22px;
    letter-spacing: -0.22px;
}
.sider-header-container .profile-icon{
    position: absolute;
    right: 25px;
}
.venue-info-container {
    padding: 40px;
}
.venue-info-container .restaurant-stats-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 250px;
    margin-top: 20px;
}
.venue-info-container .restaurant-stats-container img{
    margin-top: -3px;
    margin-right: 3px;
}
.venue-info-container .restaurant-stats-container .ratings{
    color: var(--form-title-color) !important;
}
.venue-info-container .location-links img{
    margin-right: 10px;
}
.venue-info-container .location-links div{
    margin-bottom: 10px;
    text-decoration: none;
}
.venue-info-container a {
    text-decoration: none !important;
    color: var(--form-text-color);
}
.venue-info-container .address {
    color: var(--form-text-color) !important;
}

.venue-info-container .delivery-time-tabs {
    color: var(--title-color) !important;
    font-size: 16px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 20px;
}
.venue-info-container .delivery-time-tabs span{
    margin-right: 20px;
}
.hour-info-container .day {
    font-size: 17px;
    color: var(--paragraph-color);
    font-weight: lighter;
    font-family: var(--font-regular);
}
.hour-info-container .time {
    font-size: 17px;
    color: var(--primary-color);
    font-weight: lighter;
    font-family: var(--font-regular);
    letter-spacing: -0.44px;
}
.item-card-desktop .itemImage {
    border-radius: 8px;
}

.item-card-desktop .single-item-variant div{
    border-radius: 5px;
    border: 1px solid #00000014;
    padding: 12px 9px;
    height: 30px;
    color: #6E8691;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-top: 0px;
    font-size: 13px;
    font-family: var(--font-regular);
}

.fooder-item-mobile-card .single-item-variant div{
    border-radius: 5px;
    border: 1px solid #00000014;
    padding: 12px 9px;
    height: 30px;
    color: #6E8691;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-top: 0px;
    font-size: 13px;
    font-family: var(--font-regular);
}

.item-card-desktop .add-to-cart-container {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    background-color: #FFFFFF;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.item-card-desktop .item-name {
    font-family: var(--font-medium);
    font-size: 16px;
    color: var(--title-color);
    font-weight: 600;
}
.item-card-desktop .restaurant-name {
    padding-top: 5px;
    margin-bottom: 5px !important;
}
.item-card-desktop .item-detail-container img{
    margin-right: 5px;
}
.item-card-desktop .item-detail-container{
    margin-right: 5px;
}
.item-card-desktop .item-detail-container span{
    color: var(--paragraph-color);
}
.item-card-desktop .item-detail-container span{
  color: var(--paragraph-color);
}
.mobile-category-container .category-label {
    display: inline-block;
    text-align: center;
    padding-right: 28px;
    padding-top: 14px;
    padding-bottom: 14px;
    text-decoration: none;
    font-size: 20px;
    font-family: var(--bold-title-font);
}
.mobile-category-container .right-overlay {
    position: absolute;
    top: 0;
    right: 45px;
    background-image: linear-gradient(-45deg, #fafafa, transparent);
    width: 50px;
    height: 50px;
}

#category-label-span:hover{
  color: #706d6d;
}

.desktop-category-container .category-label {
  display: inline-block;
  text-align: center;
  padding-right: 28px;
  padding-top: 14px;
  padding-bottom: 14px;
  text-decoration: none;
  font-size: 20px;
  font-family: var(--bold-title-font);
}
.desktop-category-container .right-overlay {
  position: absolute;
  top: 0;
  right: 45px;
  background-image: linear-gradient(-45deg, #fafafa, transparent);
  width: 50px;
  height: 50px;
}

.desktop-category-container::-webkit-scrollbar {
  height: 7px;
}

/* Track */
.desktop-category-container::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 2px grey; 
  border-radius: 10px; */
  
}

 
/* Handle */
.desktop-category-container::-webkit-scrollbar-thumb {
  background: rgb(236, 224, 224); 
   /* border-radius: 2px;  */
   width: 100px;
}

/* Handle on hover */
.desktop-category-container::-webkit-scrollbar-thumb:hover {
  background: rgb(199, 190, 190); 
}


.site-drawer-render-in-current-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 2px;
}
.fooder-item-mobile-card {
    margin: 1rem 0rem;
}
.fooder-item-mobile-card .item-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 80px;
    width: 140px;
     border-radius: 2px; 
}
.fooder-item-mobile-card h6 {
    margin-bottom: 4px !important;
    max-height: 19px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.fooder-item-mobile-card .button-container {
    height: 34px;
    width: 34px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.filter-desktop-container {
    padding-top: 0px !important
}
.filter-desktop-container span{
    font-family: var(--font-regular) !important;
    line-height: 1;
}
.filter-desktop-container .filter-items-container {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    display: none !important;
}
.filter-desktop-container .MuiGrid-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.filter-desktop-container .servings-container{
    padding-right: 0px !important;
}
.filter-desktop-container .PrivateValueLabel-circle-20{
    background-color: transparent;
    box-shadow: none;
}
.filter-desktop-container .jss20{
    background-color: transparent;
    box-shadow: none;
}
.filter-desktop-container .servings-container button{
    min-width: 30px;
    height: 30px;
    box-shadow: none;
    margin: 0px 5px;
    font-size: 12px !important;
}
.filter-mobile-container {
    margin-top: 20px !important;
    margin-bottom: 0px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: rgb(255, 248, 244);
}
.filter-mobile-container .servings-container button{
    min-width: 20px;
    height: 20px;
    box-shadow: none;
    margin: 0px 2px;
    font-size: 6px !important;
}
.filter-mobile-container .PrivateValueLabel-circle-20{
    background-color: transparent !important;
    box-shadow: none !important;
}
.filter-mobile-container .jss41{
    background-color: transparent !important;
    box-shadow: none !important;
}
.filter-mobile-container span{
    font-family: var(--font-regular) !important;
    line-height: 1;
    font-size: 0.9rem;
}
.filter-mobile-container .MuiSlider-markLabel{
    display: none;
}
.restaurant-home-right-drawer .right-drawer-paper {
    width: 400px;
    overflow-x: hidden;
    padding-bottom: 20px;
}
@media (max-width: 600px) {
    .restaurant-home-right-drawer .right-drawer-paper {
        width: 100% !important;
    }
}
.item-detail-sidebar-container .item-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 235px;
    width: 100%;
}
.item-detail-sidebar-container .item-details {
    background-color: #FFFFFF;
    border-radius: 20px 20px 0px 0px !important;
    min-height: 200px;
    margin-top: -20px;
}
.item-detail-sidebar-container .item-details .item-price{
    background-color: var(--primary-color);
    color: #FFFFFF;
    font-family: var(--font-medium);
    font-size: 1rem;
    border-radius: 50px;
    height: 40px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20px;
}
.item-detail-sidebar-container .item-details .item-quantity{
    background-color: #FFF8F4;
    font-family: var(--font-medium);
    font-size: 1rem;
    border-radius: 50px;
    height: 50px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: -25px;
}
.item-detail-sidebar-container .item-details .item-quantity svg{
    height: 24px;
    width: 24px;
}
.item-detail-sidebar-container .item-details .restaurant-name {
    font-size: 14px;
    font-family: var(--paragraph-font) !important;
}
.item-detail-sidebar-container .item-details .item-description {
    font-family: var(--paragraph-font) !important;
}
.item-detail-sidebar-container .item-details .item-quantity .plus{
    color: var(--primary-color);
}
.item-detail-sidebar-container .item-details .item-quantity .minus{
    color: #B9BFDD;
}
.item-detail-sidebar-container .item-details .item-quantity .qty{
    height: 34px;
    width: 34px;
    border-radius: 100px;
    box-shadow: 0px 0px 7px #B9B9B980;
    display: flex;
    align-items: center;
    justify-content: center;
}
.item-detail-sidebar-container .item-details .varient-container h4{
    font-size: 16px;
    color: #6E8691;
    font-family: var(--font-medium);
}
.item-detail-sidebar-container .item-details .varient-container button{
    margin-right: 10px;
    background-color: white;
    border: 1px solid #00000014;
    color: #6E8691;
    height: 34px;
}
.item-detail-accordion {
    width: 100%;
    border-bottom: 0px;
    border-top: 0px;
    box-shadow: none !important;
}
.item-detail-accordion::before {
    height: 0px !important;
}
.item-detail-accordion h4{
    font-size: 16px;
    color: #6E8691;
    font-family: var(--font-medium);
}
.options-container h5 {
    color: #6E8691 !important;
    margin-bottom: 20px !important;
}
.options-container .option-container {
    border: 1px solid #00000014;
    border-radius: 10px;
    height: 58px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    padding-top: 25px !important;
    margin-bottom: 5px;
}
.ant-radio-inner {
    width: 20px;
    height: 20px;
}
.ant-radio-checked .ant-radio-inner{
    border-color: var(--primary-color) !important;
}
.ant-radio-checked .ant-radio-inner::after{
    background-color: var(--primary-color) !important;
    width: 12px !important;
    height: 12px !important;
    border-radius: 30px !important;
}
.item-modifier-container {
    border: 1px solid #00000014;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 5px;
}
.item-modifier-container .modifier-details h4{
    margin-bottom: 0px !important;
    font-size: 20px;
}
.item-modifier-container .modifier-actions .item-quantity{
    background-color: transparent !important;
    padding-top: 25px;
    margin-right: -10px;
}
.cart-actions {
    text-align: center;
    font-family: var(--font-regular) !important;
    box-shadow: 0px 0px 6px #00000029 !important;
    background-color: #FFFFFF;
    z-index: 100;
    position: fixed;
    bottom: 0px;
}
.cart-actions h4{
    font-size: 17px;
    margin-top: 10px;
}
.cart-actions .add-to-cart-button {
    width: 100%;
    background-color: var(--primary-color);
    color: #FFFFFF;
}
.cart-actions .add-to-cart-button:hover {
    width: 100%;
    background-color: var(--primary-color);
    color: #FFFFFF;
}
.cart-actions .add-to-cart-button img {
    height: 20px;
    width: 20px;
    margin-top: -5px;
    margin-right: 10px;
}
.cart-sidebar-content {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
}
.mini-cart-container img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
}
.mini-cart-container h4 {
    font-size: 17px;
    margin-bottom: 0px !important;
    padding-top: 2px;
}
.mini-cart-container p {
    font-size: 12px;
    margin-bottom: 0px !important;
    font-family: var(--font-regular) !important;
    font-weight: 400;
}
.single-cart-item .item-image {
    width: 46px;
    height: 46px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
}
.single-cart-item h4 {
    font-size: 15px;
    margin-bottom: 0px !important;
}
.single-cart-item p {
    font-size: 13px;
    color: #6E8691;
}
.single-cart-item-actions .delete {
    height: 30px;
    width: 30px;
    border-radius: 8px 0px 0px 8px;
}
.single-cart-item-actions .minus {
    background-color: #f1f2ff;
    height: 30px;
    width: 30px;
    padding-right: 5px;
    padding-left: 5px;
}
.single-cart-item-actions .plus {
    background-color: #FA6400;
    height: 30px;
    width: 30px;
    border-radius: 0px 8px 8px 0px;
}
.single-cart-item {
    max-width: 350px !important;
    border-top: 1px solid #B9BFDD;
    padding-top: 20px;
    margin-top: 20px !important;
}
.single-cart-item-addons div{
    border-radius: 5px;
    border: 1px solid #00000014;
    padding: 12px 9px;
    height: 30px;
    color: #6E8691;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-top: 8px;
    font-size: 13px;
    font-family: var(--font-regular);
}
.checkout-button-container{
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}
.checkout-button-container button{
    width: 100%;
    background-color: var(--primary-color);
    color: white;
    font-family: var(--font-regular);
    font-size: 16px;
}
.checkout-button-container button:hover{
    width: 100%;
    background-color: var(--hover-button-color);
    color: white;
    font-family: var(--font-regular);
    font-size: 16px;
}
.checkout-container .checkout-header p {
    position: relative;
    top: 60px;
    left: 80px;
    font-size: 17px;
    color: #6E8691 !important;
    font-family: var(--font-medium) !important;
}
.checkout-container .single-cart-item {
    margin-top: 5px !important;
    padding-top: 15px !important;
}
.right-sidebar-content {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
}
.order-modifier-container {
    margin-top: 15px;
}
.order-modifier-container .modifier-item{
    margin-top: 10px;
}
.order-modifier-container h4 {
    font-size: 15px;
    margin-bottom: 5px !important;
}
.order-modifier-container p {
    font-size: 11px;
    font-family: var(--font-medium) !important;
    color: #6E8691 !important;
}
.order-summary-container h4 {
    font-size: 15px;
    font-family: var(--font-regular) !important;
}
.order-summary-container .total {
    font-size: 20px;
    font-family: var(--bold-title-font) !important;
    color: var(--primary-color) !important;
}
.delivery-method-container .radio-list {
    padding: 20px 10px;
    border: 1px solid #00000014;
    border-radius: 10px;
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    margin-bottom: 10px;
}
.delivery-address-list .add-button {
    color: var(--primary-color) !important;
    font-family: var(--font-medium) !important;
    margin-top: -4px;
}
.address-card-container {
    padding: 20px 10px;
    border: 1px solid #00000014;
    border-radius: 10px;
    margin-bottom: 10px;
}
.address-card-container .edit-button{
    color: var(--primary-color) !important;
    font-family: var(--font-medium) !important;
}
.address-card-container h4 {
    font-size: 16px;
}
.address-card-container p {
    font-size: 14px;
    font-family: var(--font-regular) !important;
    color: #6E8691 !important;
}
.full-width-button {
    background-color: var(--primary-color) !important;
    color: #FFFFFF !important;
    font-family: var(--font-medium) !important;
    width: 100% !important;
    height: 56px;
    font-size: 1rem !important;
    border-radius: 10px !important;
}
.form-label-span {
    font: var(--font-medium);
    text-align: left;
    font-size: 13px;
    color: #6E8691;
}
.geo-location-input {
    width: 100%;
    height: 45px;
    background-color: #F3F5F7;
    border-radius: 10px;
    opacity: 1;
    border: none;
    padding: 0px 10px;
    margin-top: 10px;
    color: var(--title-color);
}
.geo-location-input:focus {
    outline: none !important;
}
.map-container {
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 20px;
}
.MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--primary-color) !important;
}
.default-location-label {
    font: var(--font-regular) !important;
    text-align: left;
    letter-spacing: -0.18px;
    color: #6E8691;
    opacity: 1;
}
.profile-container .username {
    margin-bottom: 0px !important;
}
.profile-container .address {
    color: #6E8691 !important;
}
.profile-container .link-label {
    font-family: var(--font-regular) !important;
    font-size: 14px;
    padding-top: 2px;
}

.my-orders-container .order-list-background {
    background-color: #f3f5f7;
    margin-top: 30px;
    border-radius: 20px 20px 0px 0px;
}

.my-orders-container .orders-list .order-item {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 10px;
    min-height: 80px;
}
.my-orders-container .orders-list .order-item h5{
    font-family: var(--bold-title-font);
    font-size: 1rem;
    margin-bottom: 5px !important;
}
.my-orders-container .orders-list .order-item p{
    font-family: var(--font-regular);
    font-size: 14px;
    margin-bottom: 0px !important;
}
.my-orders-container .orders-list .order-item .price{
    color: var(--primary-color) !important;
}
.order-detail-container .order-status {
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    height: 40px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}
.order-detail-container .order-id-container h5{
    font-size: 20px;
    margin-bottom: 0px !important;
}
.order-detail-container .order-id-container p{
    font-size: 0.9rem;
    color: #6E8691;
    font-family: var(--font-regular) !important;
}
.order-detail-container .order-details-background {
    background-color: #f3f5f7;
    margin-top: 30px;
    border-radius: 20px 20px 0px 0px;
}
.order-detail-container .delivery-details .MuiListItemText-primary{
    font-family: var(--font-regular) !important;
    font-size: 14px !important;
    color: var(--text-dark-blue);
    padding-left: 5px;
    line-height: 1.3;
}
.order-detail-container .delivery-details .MuiListItem-root {
    padding-top: 0px !important;
}
.order-detail-container .delivery-details svg{
    margin-top: -1px;
}
.order-detail-container .delivery-details .map-container{
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 5px !important;
}
.order-detail-container .highlight-bg{
    background-color: #EDF1F7;
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 60px;
}
.order-detail-container .highlight-bg h5{
    color: var(--text-dark-blue);
    font-size: 14px;
    font-family: var(--font-medium);
}
.order-detail-container .highlight-bg p{
    color: var(--text-dark-blue);
    font-size: 14px;
    font-family: var(--font-regular) !important;
    margin-top: -2px !important;
    padding-bottom: 10px;
}

.order-detail-container .order-item-card {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
}
.order-detail-container .order-item-card h4{
    font-size: 15px;
    margin-bottom: 0px !important;
}
.order-detail-container .order-item-card p{
    font-size: 13px;
    color: #6E8691;
    font-family: var(--font-regular);
    margin-bottom: 0px !important;
}
.order-detail-container .order-item-card .item-image{
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    margin-right: 10px;
}
.order-detail-container .order-item-card .quantity-container{
    position: absolute;
    right: 15px;
    margin-top: -30px;
    background-color: var(--primary-color);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 1rem;
    font-family: var(--font-regular);
    border-radius: 50px;
}
.order-detail-container .order-item-card .single-item-price{
    margin-top: 20px;
}
.order-detail-container .order-item-card .single-item-price h4{
    font-size: 1rem;
    color: var(--primary-color) !important;
}
.order-detail-modifier-list h4 {
    font-size: 14px !important;
    font-family: var(--font-medium) !important;
}
.order-detail-modifier-list p {
    font-size: 12px !important;
    font-family: var(--font-regular) !important;
}
.order-detail-container .item-qty-total {
    padding-top: 10px !important;
    background-color: var(--primary-color);
    margin-top: -8px;
    border-radius: 0px 0px 10px 10px;
    margin-bottom: 20px;
    min-height: 50px;
}
.order-detail-container .item-qty-total h4{
    color: #FFFFFF !important;
    font-size: 1rem;
}
.order-detail-container .order-summary h4{
    font-size: 15px;
    font-family: var(--font-medium) !important;
}
.order-detail-container .order-summary .total h4{
    font-size: 18px;
    color: var(--primary-color) !important;
    font-family: var(--bold-title-font) !important;
}
.order-detail-container .payment-details h4{
    font-size: 15px;
    color: var(--text-dark-blue) !important;
    font-family: var(--bold-title-font) !important;
}
.order-detail-container .payment-details span{
    background-color: #06C167;
    padding: 5px 10px;
    color: #FFFFFF;
    border-radius: 20px;
    font-size: 12px;
}
.profile-detail-container .MuiListItem-root{
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.profile-detail-container .MuiListItemIcon-root{
    min-width: 35px !important;
}
.profile-detail-container .MuiListItemSecondaryAction-root{
    right: 0px !important;
}
.profile-detail-container .MuiListItemSecondaryAction-root span{
    font-family: var(--font-regular) !important;
}
.profile-detail-container .MuiListItemText-root span{
    font-family: var(--font-medium);
    color: #6E8691;
}
.profile-detail-container .edit-button {
    color: var(--primary-color);
    font-size: 12px;
}
.profile-detail-container .editable-container {
    box-shadow: 0px 2px 10px #5555551A;
    border-radius: 10px;
    margin-bottom: 10px;
}
.profile-detail-container .MuiDivider-root{
    display: none;
}
.settings-container .password-settings-container p{
    color: var(--paragraph-color);
    font-size: 15px;
}
.settings-container .account-settings-title h6{
    color: #ffffff !important;
}
.settings-container .account-settings-container p{
    color: var(--danger-red);
    font-size: 15px;
    font-weight: 600;
}
.settings-container h6 {
    text-align: left;
    font-family: var(--font-regular) !important;
    letter-spacing: 0.25px;
    opacity: 1;
}
.password-reset-form-container button{
    height: 40px !important;
}
.password-reset-form-container .password-error-alert p{
    font-size: 15px;
}
.password-reset-form-container .password-error-alert .main{
    font-family: var(--font-regular) !important;
    font-size: 15px;
}
.password-reset-form-container .password-error-alert .sub{
    font-size: 15px;
    padding-left: 20px;
}
.MuiDialog-paper {
    border-radius: 10px !important;
}
.MuiDialogContent-root:last-child {
    padding-bottom: 20px;
}
.account-delete-container button {
    height: 40px !important;
}
.saved-cards-container .saved-card {
    height: 80px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 0px 10px !important;
    margin-top: 15px;
}
.saved-cards-container .saved-card .delete-btn{
    color: var(--primary-color);
    font-size: 14px;
    font-family: var(--font-regular);
}
.saved-cards-container .saved-card .card-number svg{
    color: var(--title-color);
    font-size: 8px;
}
.saved-cards-container .saved-card .expiry-details{
    color: var(--paragraph-color);
    font-size: 14px;
}
.address-container {
    margin-top: 12px;
}
.address-container p {
    color: var(--paragraph-color);
    font-size: 16px;
    margin-bottom: 0px !important;
    font-family: var(--font-regular);
}
.address-container h4 {
    font-size: 16px;
    margin-bottom: 0px !important;
    font-family: var(--font-medium) !important;
}
.address-container .edit-button {
    color: var(--primary-color);
    font-family: var(--font-regular);
}
.address-container .warning-msg {
    color: var(--danger-red);
    font-family: var(--font-regular);
    font-size: 12px;
}
.order-status-container .status-icon-container {
    border-radius: 100px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.order-status-container .status-message {
    font-family: var(--font-regular) !important;
    color: var(--paragraph-color);
    font-size: 17px;
    margin-top: 20px;
}

.selected-payment-cards-container .new-card-button p {
    font-family: var(--font-regular) !important;
    color: var(--paragraph-color);
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px !important;

}
.selected-payment-cards-container .confirm-selected-card p {
    font-family: var(--font-regular) !important;
    color: var(--paragraph-color);
    text-align: center;
}
.selected-payment-cards-container .confirm-selected-card button {
    height: 40px;
    margin-top: 10px;
}
.suggestion-item {
    font-family: var(--font-regular) !important;
    color: var(--paragraph-color);
    margin: 10px 15px;
}
.suggestion-item--active {
    font-family: var(--font-regular) !important;
    color: var(--paragraph-color);
    margin: 10px 15px;
}
.suggestions-loading {
    font-family: var(--font-regular) !important;
    color: var(--paragraph-color);
    margin: 10px 15px;
}
.delivery-closed span {
    font-family: var(--font-regular);
    color: var(--danger-red);
}
.fooder-header {
    position: sticky;
    background-color: white;
    z-index: 10;
    box-shadow: 0px 0px 6px #00000029;
    top: 0;
}
.header-placer {
    min-height: 0px;
}
.mini-cart-checkout {
    position: sticky;
    top: 0;
    padding-bottom: 20px;
}
.sider-header-sticky {
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: 0px 0px 6px #00000029;
    min-height: 88px !important;
    z-index: 10000;
}
.empty-placeholder-container h2 {
    font-family: var(--font-regular) !important;
    color: #656565 !important;
}
.mobile-restaurant-header {
    box-shadow: 0px 0px 6px #00000029;
    position: sticky;
    top: -5px;
    background-color: white;
    z-index: 1000;
}
.scrollTop {
    position: fixed; 
    width: 100%;
    bottom: 20px;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
}
.scrollTop:hover{
    opacity: 1;
}

@media(max-width:800px) {
.compact-category-menu-mobile-header{
  top:0 !important;
}
}