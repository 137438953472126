@font-face {
    font-family: 'tt_norms_probold';
    src: url('tt_norms_pro_bold-webfont.woff2') format('woff2'),
         url('tt_norms_pro_bold-webfont.woff') format('woff'),
         url('tt_norms_pro_bold-webfont.svg#tt_norms_probold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'tt_norms_promedium';
    src: url('tt_norms_pro_medium-webfont.woff2') format('woff2'),
         url('tt_norms_pro_medium-webfont.woff') format('woff'),
         url('tt_norms_pro_medium-webfont.svg#tt_norms_promedium') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'tt_norms_proregular';
    src: url('tt_norms_pro_regular-webfont.woff2') format('woff2'),
         url('tt_norms_pro_regular-webfont.woff') format('woff'),
         url('tt_norms_pro_regular-webfont.svg#tt_norms_proregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}